import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getShareText } from '@/src/sources/list/getFragment';
import { getIfScopeDomestic } from '@/src/sources/list/scope';
import { VIRTUAL_FLIGHT_NAME } from '@/src/constants/list/enums/virtualFlightInfo';
import { comfortTagsSelector } from '../baseSelector';

const findComfortTags = (flight, comfortTags) => {
	let flightNo = flight.get('operateFlightNo') || flight.get('flightNo'),
		departureCityCode = flight.get('departureCityCode'),
		arrivalCityCode =
			flight.get('stopList') && flight.get('stopList').size > 0
				? flight.getIn(['stopList', 0, 'cityCode'])
				: flight.get('arrivalCityCode'),
		comfort = comfortTags.find(
			(tag) =>
				tag.get('flightNo') === flightNo &&
				tag.get('departureCityCode') === departureCityCode &&
				tag.get('arrivalCityCode') === arrivalCityCode
		),
		hasEntertainEquipment = false,
		hasPower = false,
		hasWifi = false;

	if (comfort) {
		hasEntertainEquipment = comfort.get('hasEntertainEquipment');
		hasPower = comfort.get('hasPower');
		hasWifi = comfort.get('hasWifi');
	}

	return {
		hasEntertainEquipment,
		hasPower,
		hasWifi,
	};
};

class FlightComfortContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { key, onMouseOver, flight, comfortTags, tongChengFlightEnum } = this.props;
		const { hasEntertainEquipment, hasPower, hasWifi } = findComfortTags(flight, comfortTags);
		const highLightPlaneNo = flight.get('highLightPlaneNo', false); // 将机型高亮，兼容国内需求
		const aircraftName = flight.get('aircraftName');
		const trafficType = flight.get('trafficType');
		const operateAirlineCode = flight.get('operateAirlineCode');
		const operateAirlineName = flight.get('operateAirlineName');
		const isShare  = getIfScopeDomestic() ? !!operateAirlineCode : !!operateAirlineName;
		return (
			<div key={key} className="plane" onMouseOver={onMouseOver}>
				<span className="plane-No">
					{flight.get('flightNo')}&nbsp;
					<span className={classnames({ 'high-light': highLightPlaneNo })}>
						{/* 虚拟航班aircraftName兜底 */}
						{flight.get('virtualFlight')
							? aircraftName || VIRTUAL_FLIGHT_NAME[trafficType]?.value
							: aircraftName}
					</span>
				</span>
				{/* 虚拟航班不展示共享标签 */}
				{isShare && !flight.get('virtualFlight') && (
					<span className="plane-share" data-tip>
						{getShareText(tongChengFlightEnum)}{' '}
					</span>
				)}
				<span className="plane-service">
					{hasWifi && <i className="ico-wifi" />}
					{hasPower && <i className="ico-charge" />}
					{hasEntertainEquipment && <i className="ico-video" />}
				</span>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		comfortTags: comfortTagsSelector(state),
	};
};

export default connect(mapStateToProps)(FlightComfortContainer);
